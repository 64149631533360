<template>
    <v-dialog v-model="show" persistent max-width="1024px">
        <v-card v-if="packageInfo">
            <v-toolbar dark color="primary">
                <v-btn icon dark @click="close()">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title v-if="packageInfo">{{ $t('planPickerDialog.select-cell') }} {{ packageInfo.door_id }} {{ $t('planPickerDialog.plan') }}</v-toolbar-title>
                <v-toolbar-title v-else>{{ $t('planPickerDialog.select-cell-firs') }}</v-toolbar-title>
            </v-toolbar>
            <v-container grid-list-md fluid>
                <v-layout wrap>
                    <v-flex v-for="(plan, i) in plans" :key="i" xs12 sm12 md6 lg6>
                        <plan-card
                            :title="plan.title"
                            :rate="plan.rate"
                            :color="plan.color"
                            :notes="plan.notes"
                            height="100%"
                            choseable
                            @chose="chosePlan(plan)"
                        ></plan-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
'use strict'
import PlanCard from '@/components/PlanCard'
import { TransTypes } from '@/mixins/PutinMixin'
import i18n from '@/i18n'

export const PlanTitles = {
    default: '',
    [TransTypes.Leave]: i18n.t('planPickerDialog.settle'),
    [TransTypes.LongLeave]: i18n.t('planPickerDialog.renew')
}

export default {
    name: 'plan-picker-dialog',
    components: { PlanCard },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        packageInfo: {
            type: Object,
            default: () => { return {} }
        }
    },
    computed: {
        plans() {
            let planList = [{
                title: PlanTitles[TransTypes.Leave],
                rate: `${this.packageInfo.amount} ${i18n.t('planPickerDialog.ntd')}`,
                amount: this.packageInfo.amount,
                color: 'grey lighten-1',
                type: TransTypes.Leave,
                notes: [
                    i18n.t('planPickerDialog.single-rate-used'),
                    i18n.t('planPickerDialog.cannot-use-anymore')
                ]
            }]
            if (this.packageInfo.amount == 0 && this.packageInfo.relet_amount !== null) {
                planList = [{
                    title: i18n.t('planPickerDialog.during-lease'),
                    rate: `${this.packageInfo.amount} ${i18n.t('planPickerDialog.ntd')}`,
                    amount: this.packageInfo.amount,
                    color: 'blue-grey lighten-5',
                    type: TransTypes.Leave,
                    notes: [
                        i18n.t('planPickerDialog.note-month-exceed')
                    ]
                }]
            }
            if (this.packageInfo.relet_amount !== null) {
                planList.push({
                    title: PlanTitles[TransTypes.LongLeave],
                    rate: `${this.packageInfo.relet_amount} ${i18n.t('planPickerDialog.ntd-mth')}`,
                    amount: this.packageInfo.relet_amount,
                    color: 'cyan lighten-3',
                    type: TransTypes.LongLeave,
                    notes: [
                        i18n.t('planPickerDialog.bill-monthly-rate'),
                        i18n.t('planPickerDialog.extend-term')
                    ]
                })
            }
            return planList
        }
    },
    methods: {
        close() {
            this.$emit('update:show', false)
        },
        chosePlan(plan) {
            this.$emit('chose', this.packageInfo.track_no, plan)
            this.close()
        }
    }
}
</script>