<template>
    <v-stepper v-model="stepNo">
        <v-stepper-header>
            <template v-for="(title, index) in titles">
                <v-stepper-step :complete="stepNo > index + 1" :step="index + 1" :key="'step'+index">{{title}}</v-stepper-step>
                <v-divider v-if="index !== titles.length - 1" :key="'devider'+index"></v-divider>
            </template>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content step="1">
                <v-layout justify-center>
                    <v-flex xs12 sm6>
                        <div v-if="!isFromLinePay" class="text-xs-center">
                            <p class="subheading mb-0">{{ $t('takeout.use-cabinet-scanner') }}</p>
                            <BoxQRCode :content="qrContent" :key="qrcodeKey"></BoxQRCode>
                            <p>
                                {{ $t('takeout.wait-a-while') }}{{ $t('takeout.box-screen') }}<v-icon color="green">check</v-icon>{{ $t('takeout.no-response-press-button') }}
                                <v-btn fluid class="pa-0" fab dark color="green" @click="getBoxInformation"><v-icon>search</v-icon></v-btn>
                            </p>
                            <!-- <p>{{$t('takeout.renew-qrcode')}}<v-btn fluid class="pa-0" fab dark color="green" @click="reloadqrcode"><v-icon>cached</v-icon></v-btn></p> -->
                        </div>
                        <div v-else class="text-xs-center">
                            <img src="@/assets/logo.svg" />
                            <p class="subheading">{{ message }}</p>
                        </div>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="2" class="pa-1 pt-2">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md6>
                        <package-list
                            :voiceMsg="false"
                            :allowSelectAll="false"
                            :packages="packages"
                            @confirm="confirmSelection"
                            @back="setStepNo(1)"
                        ></package-list>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="3">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-list two-line subheader>
                            <v-list-tile avatar v-for="(p, index) in takeoutPackages" :key="p.track_no">
                                <v-list-tile-content>
                                    <v-list-tile-title class="title">{{ $t('takeout.cell') }} {{ p.door_id }}</v-list-tile-title>
                                    <v-list-tile-sub-title>{{ $t('retrieve.takeout-name') }}&nbsp;{{ typeName(p.product_name) }}</v-list-tile-sub-title>
                                    <v-list-tile-sub-title v-show="p.message" class="error--text">{{ p.message }}</v-list-tile-sub-title>
                                </v-list-tile-content>
                                <v-list-tile-action>
                                    <v-btn v-if="p.openable" dark color="cyan" class="subheading" @click="reopen(index)">{{ $t('takeout.reopen') }}</v-btn>
                                    <v-chip v-else label diabled outline color="error" class="subheading">{{ $t('takeout.can-not-open') }}</v-chip>
                                </v-list-tile-action>
                            </v-list-tile>
                        </v-list>
                        <p v-for="p in takeoutPackages" :key="p.track_no">
                            <v-alert :value="true" type="warning" class="subheading" v-if="istakepagePackagealarm(p)">
                                {{ $t('takeout.sure-close') }} <b style='color:black;'>{{ $t('takeout.monthly-cell') }} {{ p.door_id }} ]</b> {{ $t('takeout.avoid-loss') }}
                            </v-alert>
                        </p>
                        <v-btn large block class="subheading" color="primary" @click="leave()">{{ $t('takeout.done') }}</v-btn>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err" :timeout="dialog.timeout"></waiting-dialog>
            <message-dialog
                :show.sync="msgDialog.show"
                :msg="msgDialog.msg"
                :primary-btn="msgDialog.primaryBtn"
            ></message-dialog>
            <v-dialog persistent v-model="emptyDialog" width="250">
                <v-card class="text-xs-center">
                    <v-card-text  class="title">
                        {{ dialogMessage }}
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn block class="subheading" color="primary" @click="leave()"> {{ $t('takeout.back') }} </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-stepper-items>
    </v-stepper>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import BoxQRCode from '@/components/BoxQRCode'
import WaitingDialog from '@/components/WaitingDialog'
import PackageList from '@/components/PackageList'
import BoxIdWatcherMixin from '@/mixins/BoxIdWatcher'
import ControlResultWatcher from '@/mixins/ControlResultWatcher'
import WaitDialogControl from '@/mixins/WaitDialogControl'
import MessageDialog from '@/components/MessageDialog.vue'
import MessageDialogControl from '@/mixins/MessageDialogControl.vue'
import { HostUrl, SideBtnType } from '@/store'
import { getAxiosConfig } from '@/utils/AuthService'
import { HOMETYPES } from '@/utils/utils'
import { publish } from '@/utils/MQTTClient'
import { TransTypes } from '@/mixins/PutinMixin'
import i18n from '@/i18n'
import { MQTT_ACTION, handleSelectBox } from '@/utils/MQTTClient'

export default {
    name: 'LogisticsRetrieve',
    components: { BoxQRCode, WaitingDialog, PackageList, MessageDialog },
    mixins: [ BoxIdWatcherMixin, ControlResultWatcher, WaitDialogControl, MessageDialogControl ],
    data() {
        return {
            titles: [i18n.t('retrieve.pickup-QR-code'), i18n.t('retrieve.pick-list'), i18n.t('retrieve.finish-pick-list')],
            stepNo: 1,
            qrContent: { action: 'auth' },
            qrcodeKey: 0,
            packages: [],
            emptyDialog: false,
            billingTime: new Date().getTime(),
            takeoutPackages: [],
            selectTrackNos: null,
            isFromLinePay: false,
            dialogMessage: i18n.t('retrieve.takeout-name'),
            message: i18n.t('takeout.line-pay-transaction')
        }
    },
    watch: {
        boxId() {
            if (this.boxId) {
                if (store.getters.user.box_owner != null && !store.getters.user.box_owner.includes(this.boxId)) {
                    store.commit('setSnackBar', { message: this.boxId + ' ' + i18n.t('doorSelector.cabinet-not-set'), color: 'error' })
                    this.leave()
                    return
                }
                if (this.isFromLinePay) return; 
                var self = this
                self.getPackages(function (packages) {
                    if (packages.length === 0) self.emptyDialog = true
                    else if (packages.length === 1 && self.totalAmount === 0) self.openDoors()
                    else self.setStepNo(2)
                })
            }
        },
        controlResult() {
            this.closeDialog()
            this.updatePackagesWithControlResult()
            this.setStepNo(3)
        }
    },
    computed: {
        selectedPackages() {
            return this.packages.filter(p => p.track_no in this.selectTrackNos)
        },
        doorIds() {
            return this.selectedPackages ? this.selectedPackages.map(function(e) { return e.door_id }) : []
        },
        totalAmount() {
            if (this.selectedPackages) return this.selectedPackages.reduce((s, p) => s + this.finalAmount(p), 0)
            else return 0
        }
    },
    methods: {
        initAll() {
            store.commit('initTransaction');
            store.commit('setTitle', this.titles[0]);
            store.commit('setTargetBoxId', '');
            store.commit('setReopenTimeLimit', false);
            store.commit('setMQTTPayload', null);
            this.boxId = ''
            this.stepNo = 1
            this.emptyDialog = false
            this.packages = []
            this.takeoutPackages = []
            this.selectTrackNos = null
            this.isFromLinePay = false
            store.commit('setIsFromLinePay', false)
        },
        reloadqrcode(){
            store.commit('setClientId')
        },
        setStepNo: function(no) {
            if (no === 1) this.initAll();
            this.stepNo = no;
            store.commit('setTitle', this.titles[this.stepNo - 1]);
            this.closeDialog()
        },
        oneDoor() {
            //console.log('role: '+ store.getters.user.isWashClerk)
            if (this.packages !== []) {
                if (this.packages.length === 1 && !store.getters.user.isWashClerk){
                    return true
                }
                else return false
            }
            else return false
        },
        async getPackages(handler = null) {
            this.showDialog(i18n.t('retrieve.get-list'), i18n.t('takeout.fail-get-list'), 15000)
            this.billingTime = new Date().getTime()
            let url = `${HostUrl}/api/staff/${this.boxId}/retrieve/packages`
            if (store.getters.pageHome === HOMETYPES.WASH) {
                let trans_type = 5 // 5: backend TransStypes.WASHCLIENT
                url = `${HostUrl}/api/store/${this.boxId}/`+trans_type+`/retrieve/packages`
            } else if (store.getters.pageHome === HOMETYPES.TRAVELDOCS) {
                let trans_type = 9 // 9: backend TransTypes.DOCSCLIENT
                url = `${HostUrl}/api/store/${this.boxId}/`+trans_type+`/retrieve/packages`
            }
            //console.log('getPackage url:'+ url)
            try {
                let response = await axios.get(url, getAxiosConfig())
                if (response.data.code == 0) {
                    this.packages = response.data.packages
                    if (this.selectTrackNos === null) {
                        this.selectTrackNos = {}
                        this.packages.forEach(p => { this.selectTrackNos[p.track_no] = p.type })
                    }
                } else throw new Error(response.data.message)
            } catch (error) {
                store.commit('setSnackBar', { message: error.message, color: 'error' })
            } finally {
                this.closeDialog()
            }
            // 於取得新的 Packages 列表後執行 handler hook
            // 用於首次進來此頁面根據 package 內容判斷要跳到哪一頁的動作
            if (handler) handler(this.packages)
        },
        confirmSelection(selections) {
            this.selectTrackNos = selections
            this.openDoors()
        },
        finalAmount(p) {
            let type = this.selectTrackNos[p.track_no]
            switch (type) {
                case TransTypes.Delivery:
                case TransTypes.Leave:
                    return p.amount
                case TransTypes.StoreHost:
                    return p.amount
                case TransTypes.WashClient:
                    return p.amount
                case TransTypes.DocsClient:
                    return p.amount;
                case TransTypes.LongLeave:
                    return p.relet_amount || p.amount // 針對 relet_amount 為 null 的狀況, 表示沒有續租方案, 使用 amount 為金額
                default:
                    store.commit('setSnackBar', { message: i18n.t('takeout.calculation-wrong'), color: 'error' })
                    return undefined
            }
        },
        openDoors() {
            let url = ""
            let payload = {
                client_id: store.getters.clientId,
                track_no: this.selectTrackNos
            }
            url = `${HostUrl}/api/staff/${this.boxId}/retrieve/control`;
            this.showDialog(i18n.t('takeout.open-cell'), i18n.t('takeout.fail-open-cell'), 40000)
            var self = this
            axios.post(url, payload, getAxiosConfig())
                .then(function (response) {
                    if (response.data.code === 0) {
                        if(response.data && response.data.payment_url && response.data.payment_url.web) {
                            window.location.href = response.data.payment_url.web
                        } else {
                            self.takeoutPackages = response.data.packages
                        }
                        if (!self.takeoutPackages) self.emptyDialog = true
                    } else if (response.data.code === 508) {
                        if (response.data.message === '付款完成') response.data.message = i18n.t('putIn.payment-completed')
                            self.message = response.data.message
                            self.dialogMessage = response.data.message
                            self.emptyDialog = true
                            self.closeDialog()
                    } else if (response.data.code === 509) {
                        self.$refs.paymentSelector.$refs.directPay[0].payByPrime()
                        store.commit('setSnackBar', { message: i18n.t('putIn.transaction-fail'), color: 'error' })
                        self.closeDialog()
                    } else {
                        if (response.data.message === '密碼錯誤, 請使用註冊時，輸入的密碼') {
                            response.data.message = i18n.t('takeout.password-wrong')
                        }
                        if (response.data.message === '密碼錯誤, 請輸入信用卡前六碼') {
                            response.data.message = i18n.t('takeout.enter-six-digits')
                        }
                        if (response.data.message === '密碼錯誤, 請輸入設定的密碼') {
                            response.data.message = i18n.t('takeout.password-you-set')
                        }
                        self.closeDialog()
                        store.commit('setSnackBar', { message: response.data.message, color: 'error' })
                    }
                })
                .catch(function () {
                    self.closeDialog()
                    store.commit('setSnackBar', { message: i18n.t('takeout.transaction-fail'), color: 'error' })
                })
        },
        istakepagePackagealarm(p){
            if (this.isFromLinePay) {
                if (p.type === TransTypes.LongLeave) {
                    return true
                } else return false
            } else {
                if (p.type === TransTypes.LongLeave) {
                    if (p.amount < p.relet_amount & p.amount != 0 & this.selectTrackNos[p.track_no] == TransTypes.Leave)
                        return false
                    else
                        return true
                }
                else
                    return false
            }
        },
        typeName(door_name) {
            if (door_name) {
                if (door_name.length > 8) return door_name.substr(0, 8)
                else return door_name
            } else return ""
        },
        reopen(index) {
            //限制不得超過50秒後按重開按鍵
            let overTime = store.getters.mqttPayload + 51000
            let currentTime = new Date().getTime()
            if(currentTime > overTime) {
                this.showMsgDialog(i18n.t('takeout.cannot-taken-out'), i18n.t('takeout.close'))
                store.commit('setReopenTimeLimit', false)
                return
            }
            this.showDialog(i18n.t('takeout.open-cell'), i18n.t('takeout.fail-open-cell'), 40000)
            var topic = `box/${this.boxId}/trans/control`
            let p = this.takeoutPackages[index]
            let transtype = p.type
            if (p.type == TransTypes.LongLeave & p.amount < p.relet_amount & p.amount != 0) {
                transtype = this.selectTrackNos[p.track_no]
            }
            publish(topic, {
                user_id: store.getters.user.id,
                client_id: store.getters.clientId,
                action: MQTT_ACTION.RETRIEVE_ACTION,
                track_no: { [p.track_no] : {
                    door_id: p.door_id,
                    trans_type: transtype
                }}
            }, 1)
        },
        updatePackagesWithControlResult: function() {
            this.takeoutPackages.filter(p => p.track_no in this.controlResult).forEach(p => {
                if (this.controlResult[p.track_no] < 0) p.message = i18n.t('takeout.fail-open');
                else p.message = ''
            })
            store.commit('setReopenTimeLimit', true)
        },
        leave() {
            this.initAll();
            this.$router.push(`/${i18n.locale}/logistics/menu`)
        },        
        async getBoxInformation() {
            this.showDialog(i18n.t('retrieve.get-list'), i18n.t('takeout.fail-get-list'), 15000)
            let url = `${HostUrl}/api/user/token/checkboxid`
            let payload = store.getters.userInfo
            try {
                let response = await axios.post(url, payload, getAxiosConfig())
                if (response.data.code == 0) {
                    handleSelectBox(response.data)
                } else throw new Error(response.data.message)
            } catch (error) {
                if (error.message === "請掃描 QR code") {
                    error.message = i18n.t('takeout.scan-QR-code')
                }
                store.commit('setSnackBar', { message: error.message, color: 'error' })
            } finally {
                this.closeDialog()
            }
        }
    },
    activated() {
        store.commit('setSideBtnType', SideBtnType.Back)
        this.initAll()
    },
    deactivated() {
        store.commit('setSideBtnType', SideBtnType.Navi)
    }
};
</script>

<style scoped>
</style>
